import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import $ from 'jquery';
import * as _ from 'lodash';
import moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { combineLatest, takeUntil } from 'rxjs';

import {
  BI_ROOT_ROUTE,
  BiPermissionService,
  BoardDataSource,
  Dashboard,
  DashboardDomainService,
  Datasource,
  PermissionForEntityFactory,
  PresentationDashboard,
  UserProfile,
  Workbook,
  WorkbookDetailProjections,
  Workspace,
  convertDsToMetaDs,
  createWorkbookDetailProjections,
} from '@selfai-platform/bi-domain';
import { BiEntityPermission, DestroyService } from '@selfai-platform/shared';

import { AbstractComponent } from '../common/component/abstract.component';
import { DeleteModalComponent } from '../common/component/modal/delete/delete.component';
import { CookieConstant } from '../common/constant/cookie.constant';
import { Modal } from '../common/domain/modal';
import { EventBroadcaster } from '../common/event/event.broadcaster';
import { MomentDatePipe } from '../common/pipe/moment.date.pipe';
import { MomentPipe } from '../common/pipe/moment.pipe';
import { PopupService } from '../common/service/popup.service';
import { CommonUtil } from '../common/util/common.util';
import { StringUtil } from '../common/util/string.util';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DashboardService } from '../dashboard/service';
import { DashboardApiLegacyService } from '../dashboard/service/dashboard-api.service';
import { WidgetService } from '../dashboard/service/widget.service';
import { UpdateDashboardComponent } from '../dashboard/update-dashboard.component';
import { Comment, Comments } from '../domain/comment/comment';
import { Page, PageResult } from '../domain/common/page';
import { PublicType } from '../domain/workspace/workspace';
import { WorkspaceService } from '../workspace/service/workspace.service';

import { PopupInputNameDescComponent } from './component/popup-input-workbook/popup-input-namedesc.component';
import { WorkbookConfirmDelete } from './component/workbook-confirm-delete/workbook-confirm-delete';
import { WorkbookNavigationComponent } from './component/workbook-navigation/workbook-navigation.component';
import { WorkbookService } from './service/workbook.service';

@Component({
  selector: 'app-workbook',
  templateUrl: './workbook.component.html',
  styleUrls: ['./workbook.component.scss'],
  providers: [MomentPipe, MomentDatePipe, DestroyService],
})
export class WorkbookComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild(PopupInputNameDescComponent, { static: true })
  private _popupInputNameDescComp: PopupInputNameDescComponent;

  @ViewChild(DeleteModalComponent, { static: true })
  private deleteModalComponent: DeleteModalComponent;

  @ViewChild(DashboardComponent)
  public _boardComp: DashboardComponent;

  @ViewChild(UpdateDashboardComponent)
  private _updateBoardComp: UpdateDashboardComponent;

  @ViewChild('srchDashboard')
  private inputSrchDashboard: ElementRef;

  @ViewChild(WorkbookNavigationComponent, { static: false })
  _workbookNavigationComponent: WorkbookNavigationComponent;

  @ViewChild(WorkbookConfirmDelete)
  _workbookConfirmDeleteComponent: WorkbookConfirmDelete;

  @Input() gridMenuState: boolean;

  addGridMenuState(value: boolean): void {
    this.gridMenuState = value;
  }

  @Output() confirmDel = new EventEmitter<boolean>();
  @Output() confirmCancel = new EventEmitter<boolean>();

  public setModalClose = false;

  public workbookId: string;

  public dashboardId: string;

  private cookieCommentId: any;

  public workspacePermission: PermissionForEntityFactory;

  public mode: string;

  public $window = window;

  public isShowCreateDashboard: boolean;

  public isShowLnbLoading = false;

  public leftTab: string;

  public dashboards: Dashboard[];

  public workspace: Workspace;
  public tempLoadBoard: Dashboard;
  public selectedDashboard: Dashboard;

  public updateDashboardStartupCmd: { cmd: string; id?: string; type?: string };

  public datasources: any[];

  public selectedDatasources: string[];

  public comments: Comments;

  public isNewComment: boolean;

  public username: string;

  public commentText: string;

  public workbook: WorkbookDetailProjections;

  public onlyShowingFlag = false;

  public listType: string;

  public dashboardPage: PageResult = new PageResult();
  public dashboardSrchText = '';

  public ingestionTargetDatasource: BoardDataSource;

  public isShowDetailMenu = false;
  public isShowDatasourceMenu = false;
  public isCloseDashboardList = false;
  public isShowMoreDashboardList = false;
  public isShowDataPreview = false;
  public isDashboardNameEditMode = false;
  public isDashboardDescEditMode = false;
  public isShowDashboardDetailMenu = false;
  public isShowDataIngestion = false;
  public isModalWindow = this._ptService.ptModalClose;
  public publicType = PublicType;
  public books;
  public thumbnailsVisibility = false;

  public get filteredDashboard(): Dashboard[] {
    if (this.onlyShowingFlag) {
      return this.dashboards.filter((ds) => {
        return ds.hiding === false || ds.hiding === undefined;
      });
    }

    return this.dashboards;
  }

  dashboardidLoadInProgress: string;

  biPermissionEntity = BiEntityPermission;

  constructor(
    private activatedRoute: ActivatedRoute,
    private workbookService: WorkbookService,
    private dashboardApiService: DashboardApiLegacyService,
    private dashboardDomainService: DashboardDomainService,
    private dashboardService: DashboardService,
    private workspaceService: WorkspaceService,
    private widgetService: WidgetService,
    private popupService: PopupService,
    private broadCaster: EventBroadcaster,
    private _clipboardService: ClipboardService,
    protected elementRef: ElementRef,
    protected injector: Injector,
    public _ptService: PopupService,
    private readonly permissionService: BiPermissionService,
    private readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.$window['ui'] = [{ widgets: [] }];

    this.loadingShow();

    this._initViewPage();

    this.widgetService.loadPropMapView();

    this.subscriptions.push(
      this.broadCaster.on<any>('MOVE_EDIT_WIDGET').subscribe((data) => {
        this.changeMode('UPDATE', data);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('CHANGE_ESSENTIAL_FILTER').subscribe(() => {
        try {
          this.changeMode('VIEW');

          const mainDsName: string = this.selectedDashboard.configuration.dataSource.name;
          const mainDs: Datasource = this.selectedDashboard.dataSources.find((ds) => ds.name === mainDsName);
          this.ingestionTargetDatasource = convertDsToMetaDs(mainDs);
          this.isShowDataIngestion = true;
          this.changeDetect.markForCheck();
        } catch (err) {
          console.error(err);
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('WORKBOOK_RELOAD_BOARD_LIST').subscribe((data: { boardId: string }) => {
        this._getWorkbook().then(() => {
          this.selectedDatasources = this.datasources.map((ds) => ds.id);
          this.loadDashboardList(0, data.boardId);
        });
      }),
    );

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([params, queryParams]) => {
        this.workbookId = params['workbookId'];

        this.dashboardId = params['dashboardId'] || queryParams['dashboardId'];

        this._getWorkbook().then((workbook: Workbook) => {
          this.workspaceService
            .getWorkSpace(this.workbook.workspaceId, 'forDetailView')
            .then((workspace: Workspace) => {
              this.workspace = workspace;

              this.workspacePermission = this.permissionService.getPermissionsForEntityFactory(workspace.permissions);

              if (workspace.active && this.workspacePermission.hasPermission(BiEntityPermission.WORKBOOK_VIEW)) {
                const afterCreateWorkbookCmd: string = sessionStorage.getItem('AFTER_CREATE_WORKBOOK');

                this.loadingHide();

                if ('CREATE_DASHBOARD' === afterCreateWorkbookCmd) {
                  sessionStorage.removeItem('AFTER_CREATE_WORKBOOK');
                  this.createDashboard();
                } else {
                  this.loadDashboardList(0, this.activatedRoute.snapshot.queryParamMap.get('dashboardId'));
                }
              } else {
                this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.access-denied.title'));
              }
              this.books = this.workspace.books.filter((item) => {
                if (this.workbookId == item.id) {
                  return item;
                }
              });
            });
        });

        this._getComments();
      });

    $('.ddp-layout-contents').addClass('ddp-layout-board');
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    $('.ddp-layout-contents').removeClass('ddp-layout-board');

    if (this.comments.comments.length > 0) {
      const userId = this.cookieService.get('LOGIN_USER_ID');
      this.cookieService.set(userId + this.workbookId, this.comments.comments[0].id + '', 0, '/');
    }
  }

  hasPermission(permission: BiEntityPermission): boolean {
    return this.workspacePermission?.hasPermission(permission);
  }

  public execBeforeUnload() {
    if (this.mode === 'UPDATE' && this._updateBoardComp) {
      this.useUnloadConfirm = this._updateBoardComp.execBeforeUnload();
    }
  }

  @HostListener('click', ['$event.target'])
  public clickOther(target) {
    if (this.isShowDetailMenu) {
      const $eventTarget: JQuery = $(target);
      if (
        !$eventTarget.hasClass('ddp-ui-more') &&
        0 === $eventTarget.closest('.ddp-ui-more').length &&
        !$eventTarget.hasClass('ddp-popup-lnbmore') &&
        0 === $eventTarget.closest('.ddp-popup-lnbmore').length
      ) {
        this.isShowDetailMenu = false;
      }
    }
  }

  public dateFormatTodayTimeElseDate(date: Date) {
    return moment(date).calendar(null, {
      sameDay: 'HH:mm',
      sameElse: 'YYYY.MM.DD HH:mm',
    });
  }

  public gotoWorkspace() {
    const cookieWs = this.cookieService.get(CookieConstant.KEY.CURRENT_WORKSPACE);
    const cookieWorkspace = cookieWs ? JSON.parse(cookieWs) : null;
    if (null !== cookieWorkspace && null !== cookieWorkspace['workspaceId']) {
      this.router.navigate([BI_ROOT_ROUTE, 'workspace', cookieWorkspace['workspaceId']]).then();
    } else {
      this.router.navigate([BI_ROOT_ROUTE, 'workspace']).then();
    }
  }

  public afterInputInfo(info: { type: string; name: string; desc: string; id: string }) {
    if ('UPDATE_WORKBOOK' === info.type) {
      this.loadingShow();

      const workbookInfo: Workbook = Object.assign({}, this.workbook);
      workbookInfo.name = info.name;
      workbookInfo.description = info.desc;

      this.workbookService
        .updateBook(workbookInfo)
        .then((workbook) => {
          this.alertPrimeService.success(this.translateService.instant('msg.comm.alert.save.success'));
          this.workbook.name = workbook.name;
          this.workbook.description = workbook.description;
          this.workbook.modifiedTime = workbook.modifiedTime;
          this._popupInputNameDescComp.close();
          this.loadingHide();
        })
        .catch((err) => {
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.save.fail'));
          this.commonExceptionHandler(err);
        });
    }
  }

  public deleteContent($event: Modal) {
    if ($event.data.type === 'workbook') {
      this.loadingShow();
      this.workbookService
        .deleteWorkbook(this.workbook.id)
        .then(() => {
          this.alertPrimeService.success(this.translateService.instant('msg.book.alert.workbook.del.success'));
          this.gotoWorkspace();
        })
        .catch((err) => {
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
          console.log(err);
          this.loadingHide();
        });
    } else if ($event.data.type === 'dashboard') {
      const dashboard: Dashboard = $event.data.data;

      this.loadingShow();
      this.dashboardApiService
        .deleteDashboard(dashboard.id)
        .then(() => {
          this.alertPrimeService.success(this.translateService.instant('msg.board.alert.dashboard.del.success'));

          if (this.selectedDashboard.id === dashboard.id) {
            this._setSelectedDashboard(null);
          }

          this._getWorkbook().then(() => {
            this.selectedDatasources = this.datasources.map((ds) => ds.id);
            this.loadingHide();
            this.loadDashboardList(0);
          });
        })
        .catch((err) => {
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
          console.log(err);
          this.loadingHide();
        });
    } else if ($event.data.type === 'comment') {
      this.isShowLnbLoading = true;

      this.workbookService
        .deleteComment(this.workbook.id, $event.data.data)
        .then(() => {
          this.alertPrimeService.success(this.translateService.instant('msg.board.alert.comment.del.success'));

          this._getComments();
        })
        .catch((err) => {
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
          console.log(err);
          this.isShowLnbLoading = false;
        });
    }
  }

  public changeTab(value: string) {
    if (this.leftTab === 'CHAT' && this.comments.comments.length > 0 && value === 'DASHBOARD') {
      this.isNewComment = false;

      const userId = this.cookieService.get('LOGIN_USER_ID');
      this.cookieService.set(userId + this.workbookId, this.comments.comments[0].id + '', 0, '/');
    } else if (this.leftTab === 'DASHBOARD' && value === 'CHAT') {
      this._getComments();
    }

    this.leftTab = value;
  }

  public toggleFoldDashboardList(isClose?: boolean) {
    if ('undefined' === typeof isClose) {
      this.isCloseDashboardList = !this.isCloseDashboardList;
    } else {
      this.isCloseDashboardList = isClose;
    }
    this._boardComp && this._boardComp.toggleFoldWorkbookDashboardList();

    this.cookieService.set(CookieConstant.KEY.WORKBOOK_CLOSE_DASHBOARD_LIST, String(this.isCloseDashboardList), 0, '/');
  }

  public updateWorkbook() {
    this._popupInputNameDescComp.init({
      type: 'UPDATE_WORKBOOK',
      id: this.workbook.id,
      name: this.workbook.name,
      desc: this.workbook.description,
    });
  }

  public confirmDeleteWorkbook() {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.book.ui.del.workbook.del.title');
    modal.description = this.translateService.instant('msg.book.ui.del.workbook.del.description');
    modal.subDescription = this.translateService.instant('msg.comm.ui.del.description');
    modal.btnName = this.translateService.instant('msg.book.ui.del.workbook.btn');
    modal.data = {
      type: 'workbook',
    };

    this.deleteModalComponent.init(modal);
  }

  public toggleWorkbookDetailMenu(event: MouseEvent) {
    this.isShowDetailMenu = !this.isShowDetailMenu;
    if (this.isShowDetailMenu) {
      this.safelyDetectChanges();
      let $target: JQuery = $(event.target as HTMLElement);
      $target.hasClass('ddp-icon-more') || ($target = $target.find('.ddp-icon-more'));
      const lnbmoreLeft: number = $target.offset().left;
      const lnbmoreTop: number = $target.offset().top;
      this.$element.find('.ddp-popup-lnbmore').css({ left: lnbmoreLeft - 180, top: lnbmoreTop + 25 });
    }
  }

  public toggleDatasourceLayer() {
    if (this.datasources && 0 < this.datasources.length) {
      this.isShowDatasourceMenu = !this.isShowDatasourceMenu;
    }
  }

  public selectDatasource(dsId) {
    const idx = this.selectedDatasources.indexOf(dsId);
    if (idx === -1) {
      this.selectedDatasources.push(dsId);
      this.loadDashboardList();
    } else {
      this.selectedDatasources.splice(idx, 1);

      this.selectedDatasources.length === 0 ? (this.dashboards = []) : this.loadDashboardList();
    }
    this.changeDetect.detectChanges();
  }

  public changeFavorite(dashboard: Dashboard, event) {
    event.stopImmediatePropagation();

    const params = {
      hiding: !dashboard.hiding,
    };

    this.dashboardApiService.updateDashboard(dashboard.id, params).then((ds) => {
      dashboard.hiding = ds.hiding;
    });
  }

  public changeMode(mode: string, startupCmd?: { cmd: string; id?: string; type?: string }) {
    this.updateDashboardStartupCmd = startupCmd ? startupCmd : { cmd: 'NONE' };
    this.mode = mode;
    this.safelyDetectChanges();
  }

  public changeOrder() {
    this.loadingShow();

    const params = [];
    this.dashboards.forEach((item, idx) => {
      params.push({
        op: 'REPLACE',
        hiding: item.hiding,
        id: item.id,
        seq: idx,
      });
    });

    this.workbookService
      .setDashboardSort(this.workbookId, params)
      .then(() => {
        this.alertPrimeService.success(this.translateService.instant('msg.board.alert.dashboard.sort.success'));

        this.loadingHide();
      })
      .catch(() => {
        this.alertPrimeService.error(this.translateService.instant('msg.board.alert.dashboard.sort.fail'));

        this.loadingHide();
      });
  }

  public searchDashboardByName(event: KeyboardEvent) {
    if (13 === event.keyCode) {
      this.dashboardSrchText = event.target['value'].trim();
      this.loadDashboardList(0);
    }
  }

  public isShowBtnClearSrchDashboard(): boolean {
    return 'undefined' !== typeof this.inputSrchDashboard && this.inputSrchDashboard.nativeElement.value !== '';
  }

  public clearSearchText() {
    this.inputSrchDashboard.nativeElement.value = '';
    if ('' !== this.dashboardSrchText) {
      this.dashboardSrchText = '';
      this.loadDashboardList(0);
    }
  }

  public loadDashboardList(page: number = 0, targetBoardId?: string) {
    this.loadingShow();

    if (!this.workspace) return;

    const params = {
      includeHidden: this.workspacePermission.hasPermission(BiEntityPermission.WORKBOOK_EDIT),
      datasources: this.selectedDatasources.join(','),
      size: '999',
    };
    if ('' !== this.dashboardSrchText.trim()) {
      params['nameContains'] = this.dashboardSrchText.trim();
    }

    const pageInfo: Page = new Page();
    pageInfo.page = page;

    if (0 === page) {
      this.dashboardPage = new PageResult();
      this.dashboards = [];
      this.isShowMoreDashboardList = false;
    }

    this.workbookService
      .getDashboards(this.workbook.id, { key: 'seq', type: 'asc' }, pageInfo, 'forListView', params)
      .then((result) => {
        let tempList = [];
        if (result.hasOwnProperty('_embedded') && result['_embedded'].hasOwnProperty('dashboards')) {
          tempList = result['_embedded']['dashboards'];
          tempList.forEach((ds) => {
            if (ds.hiding === undefined) ds.hiding = false;
          });

          if (!this.workspacePermission.hasPermission(BiEntityPermission.WORKBOOK_VIEW)) {
            tempList = tempList.filter((item) => !item.hiding);
          }
        }

        if (result.page) {
          const objPage: PageResult = result.page;
          this.dashboardPage = objPage;
          if (0 === objPage.number) {
            this.dashboards = tempList;
          } else {
            this.dashboards = this.dashboards.concat(tempList);
          }
          this.isShowMoreDashboardList = 0 < objPage.totalPages && objPage.number !== objPage.totalPages - 1;
        }

        if (this.mode !== 'UPDATE') {
          if (this.dashboards.length === 0) {
            this.changeMode('NO_DATA');
          } else {
            this.changeMode('VIEW');
          }
        }

        this.loadingHide();

        if (0 < this.dashboards.length) {
          if (targetBoardId) {
            const selectedBoard: Dashboard = this.dashboards.find((item) => item.id === targetBoardId);
            if (selectedBoard) {
              this.loadAndSelectDashboard(selectedBoard);
            } else {
              if (this.dashboardPage.totalPages > this.dashboardPage.number + 1) {
                this.loadDashboardList(this.dashboardPage.number + 1, targetBoardId);
              }
            }
          } else {
            const selectedDashboard = this.dashboards.find((dashboard) => {
              return dashboard.id === this.activatedRoute.snapshot.paramMap.get('dashboardId');
            });
            if (selectedDashboard) {
              this.loadAndSelectDashboard(selectedDashboard);
            }
          }
        } else {
          this._setSelectedDashboard(null);
        }

        this.safelyDetectChanges();
      })
      .catch((err) => {
        this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
        console.log(err);
        this.loadingHide();
      });
  }

  public changeListType(type: string) {
    this.listType = type;
    this.safelyDetectChanges();
    if (this.selectedDashboard) {
      this.scrollToDashboard(this.selectedDashboard.id);
    }
  }

  public scrollToDashboard(dashboardId: string) {
    const selectedIdx: number = this.dashboards.findIndex((item) => item.id === dashboardId);
    if ('LIST' === this.listType) {
      $('.ddp-ui-board-listview').animate({ scrollTop: selectedIdx * 52 }, 800, 'swing');
    } else {
      $('.ddp-ui-board-thumbview').animate({ scrollTop: selectedIdx * 185 }, 800, 'swing');
    }
  }

  public createComments($event, updateComment?: Comment) {
    if ($event.keyCode === 13) {
      if (!$event.shiftKey) {
        $event.preventDefault();

        if (updateComment) {
          if (updateComment.contents && updateComment.contents.length > 0) {
            this.isShowLnbLoading = true;
            this.workbookService
              .updateComment(updateComment, this.workbook.id)
              .then(() => {
                this._getComments();
              })
              .catch((err) => {
                this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
                console.log(err);
                this.loadingHide();
              });
          }
        } else {
          const comment = new Comment();
          comment.contents = this.commentText;

          if (this.commentText && this.commentText.length > 0) {
            this.isShowLnbLoading = true;
            this.workbookService
              .createComment(comment, this.workbook.id)
              .then(() => {
                this.commentText = '';
                this._getComments();
              })
              .catch((err) => {
                this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
                console.log(err);
                this.loadingHide();
              });
          }
        }
      }
    }
  }

  public confirmDeleteComment(commentId: string) {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.ui.del.comment.del.title');
    modal.description = this.translateService.instant('msg.board.ui.del.comment.del.description');
    modal.btnName = this.translateService.instant('msg.board.ui.del.comment.btn');
    modal.data = {
      type: 'comment',
      data: commentId,
    };

    this.deleteModalComponent.init(modal);
  }

  public getProfileImage(user): string {
    return user.hasOwnProperty('imageUrl')
      ? '/api/images/load/url?url=' + user.imageUrl + '/thumbnail'
      : 'assets/bi/images/img_photo.png';
  }

  public copyBoardIdToClipboard() {
    if (this.selectedDashboard) {
      this._clipboardService.copyFromContent(this.selectedDashboard.id);
    }
  }

  public createDashboard() {
    this.isShowCreateDashboard = true;
    this.useUnloadConfirm = true;
    this.safelyDetectChanges();
  }

  public closeCreateDashboard() {
    this.isShowCreateDashboard = false;
    this.useUnloadConfirm = false;
    this.safelyDetectChanges();
  }

  public copyDashboard(dashboard: Dashboard) {
    this.loadingShow();
    this.dashboardApiService
      .copyDashboard(dashboard.id)
      .then((copyBoard: Dashboard) => {
        this.alertPrimeService.success(
          "'" + dashboard.name + "' " + this.translateService.instant('msg.board.alert.dashboard.copy.success'),
        );
        this.loadingHide();
        this.loadDashboardList(0, copyBoard.id);
        this.goToDashboard(copyBoard);
      })
      .catch(() => this.loadingHide());
  }

  public updateDashboard(dashboard: Dashboard) {
    this.dashboards.forEach((db) => {
      if (db.id === dashboard.id) {
        if (
          this._dashboardValidation(dashboard) &&
          (db.name !== dashboard.name || db.description !== dashboard.description)
        ) {
          const param = {
            name: dashboard.name,
            description: dashboard.description,
          };

          this.loadingShow();
          this.dashboardApiService
            .updateDashboard(dashboard.id, param)
            .then(() => {
              db.name = dashboard.name;
              db.description = dashboard.description;
              this.alertPrimeService.success(this.translateService.instant('msg.comm.alert.save.success'));
              this.changeDetect.detectChanges();
              this.loadingHide();
            })
            .catch(() => {
              this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.save.fail'));
              this.loadingHide();
            });
        }
        return true;
      }
      return false;
    });
  }

  public updateCompleteDashboard(dashboard) {
    this._setSelectedDashboard(dashboard);

    this.dashboards.forEach((board) => {
      if (board.id === dashboard.id) {
        board.imageUrl = dashboard.imageUrl;
      }
    });

    this.changeMode('VIEW');
  }

  public confirmDeleteDashboard(dashboard: Dashboard) {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.ui.del.dashboard.del.title');

    modal.description = this.translateService.instant('msg.board.ui.del.dashboard.del.description');
    modal.subDescription = this.translateService.instant('msg.comm.ui.del.description');
    modal.btnName = this.translateService.instant('msg.board.ui.del.dashboard.btn');
    modal.data = {
      type: 'dashboard',
      data: dashboard,
    };

    this.deleteModalComponent.init(modal);
  }

  public detailDashboard(dashboard: Dashboard) {
    if (!this.isInvalidDatasource(dashboard)) {
      this.loadAndSelectDashboard(dashboard);
    }
  }

  public loadAndSelectDashboard(dashboard: Dashboard) {
    this.tempLoadBoard = dashboard;

    if (this.isInvalidDatasource(dashboard)) {
      if (this._boardComp) {
        this._setSelectedDashboard(undefined);
        this._boardComp.showError(this.translateService.instant('msg.space.ui.dashboard.unauthorized'));
        this._boardComp.hideBoardLoading();
      }
      return;
    }

    if (!this.selectedDashboard || this.selectedDashboard.id !== dashboard.id) {
      if (this._boardComp) {
        this._boardComp.showBoardLoading();
        this._boardComp.hideError();
      }

      if (this.dashboardidLoadInProgress !== dashboard.id) {
        this.dashboardidLoadInProgress = dashboard.id;

        this.dashboardDomainService
          .loadDashboard(dashboard.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (board: Dashboard) => {
              this.dashboardidLoadInProgress = null;
              this._setSelectedDashboard({ ...board, workBook: this.workbook });
              this.tempLoadBoard = undefined;

              this.scrollToDashboard(board.id);

              this._boardComp && this._boardComp.hideBoardLoading();
              this.safelyDetectChanges();
            },
            error: () => {
              if (this._boardComp) {
                this._boardComp.showError();
                this._boardComp.hideBoardLoading();
              }
              this.safelyDetectChanges();
            },
          });
      }
    } else {
      this._boardComp.hideBoardLoading();
      this.safelyDetectChanges();
    }
  }

  public onDashboardEvent(event: { name: string; data?: any }) {
    if ('RELOAD_BOARD' === event.name) {
      this.loadAndSelectDashboard(this.tempLoadBoard);
    }
  }

  public finishDataIngestion(tempDatasource: { id: string; info: Datasource; dataSourceId: string }) {
    const mainDsIdx: number = this.selectedDashboard.dataSources.findIndex(
      (ds) => ds.id === tempDatasource.dataSourceId,
    );

    this.loadingShow();
    const dashboard: Dashboard = _.cloneDeep(this.selectedDashboard);
    dashboard.configuration.dataSource = convertDsToMetaDs(tempDatasource.info);
    dashboard.dataSources[mainDsIdx] = tempDatasource.info;
    this.isShowDataIngestion = false;

    const param: any = { configuration: dashboard.configuration, temporaryId: tempDatasource.id };
    this.dashboardApiService.updateDashboard(dashboard.id, param).then(() => {
      this._boardComp.hideError();
      this.dashboardDomainService.loadDashboard(dashboard.id).subscribe({
        next: (board: Dashboard) => {
          this._setSelectedDashboard({ ...board, workBook: this.workbook });
          this.loadingHide();
          this.changeDetect.detectChanges();
        },
        error: () => {
          this._boardComp.showError();
          this.loadingHide();
        },
      });
    });
  }

  public gotoPresentationView() {
    this.popupService.ptDashboards = _.cloneDeep(this.dashboards);
    const boardInfo: PresentationDashboard = <PresentationDashboard>_.cloneDeep(this.selectedDashboard);
    boardInfo.selectionFilters = this._boardComp ? this._boardComp.getSelectedFilters() : [];
    this.popupService.ptStartDashboard = boardInfo;

    this.router
      .navigate([BI_ROOT_ROUTE, 'dashboard', 'presentation', this.workbook.id, this.selectedDashboard.id])
      .then();
  }

  public addWidget(type: string) {
    switch (type) {
      case 'NEW_CHART':
        this.changeMode('UPDATE', { cmd: 'NEW', type: 'CHART' });
        break;
      case 'NEW_TEXT':
        this.changeMode('UPDATE', { cmd: 'NEW', type: 'TEXT' });
        break;
      case 'NEW_FILTER':
        this.changeMode('UPDATE', { cmd: 'NEW', type: 'FILTER' });
        break;
    }
  }

  public onChangeModeName(event: MouseEvent) {
    if (this.workspacePermission.hasPermission(BiEntityPermission.DASHBOARD_EDIT)) {
      event.stopPropagation();
      this.isDashboardNameEditMode = true;
      this.isDashboardDescEditMode = false;
      this.changeDetect.detectChanges();
    }
  }

  public onChangeModeDesc(event: MouseEvent) {
    if (this.workspacePermission.hasPermission(BiEntityPermission.DASHBOARD_EDIT)) {
      event.stopPropagation();
      this.isDashboardDescEditMode = true;
      this.isDashboardNameEditMode = false;
      this.changeDetect.detectChanges();
    }
  }

  public changeDesc(inputDesc: string) {
    this.isDashboardDescEditMode = false;
    this.selectedDashboard.description = inputDesc;
    this.updateDashboard(this.selectedDashboard);
  }

  public getEditorName() {
    if (this.selectedDashboard.modifiedBy) {
      return this.selectedDashboard.modifiedBy.fullName;
    } else if (this.selectedDashboard.createdBy) {
      return this.selectedDashboard.createdBy.fullName;
    } else {
      return '';
    }
  }

  public isInvalidDatasource(dashboard: Dashboard): boolean {
    return false;
  }

  private _getWorkbook(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.workbookService
        .getWorkbook(this.workbookId)
        .then((result) => {
          this.datasources = result.dataSource.map((ds) => {
            return { id: ds.id, name: ds.name, valid: ds.valid };
          });

          if (this.selectedDatasources === undefined) {
            this.selectedDatasources = this.datasources.map((ds) => ds.id);
          }

          this.workbook = result;

          resolve(result);
        })
        .catch((err) => reject(err));
    });
  }

  private _getComments() {
    this.isShowLnbLoading = true;

    this.workbookService
      .getComments(this.workbookId, this.page)
      .then((result) => {
        if (result['_embedded']) {
          const userId = this.cookieService.get('LOGIN_USER_ID');
          this.cookieCommentId = this.cookieService.get(userId + this.workbookId);

          this.comments = result['_embedded'];

          this.comments.comments.forEach((comment) => {
            comment.isNew = !(this.username === comment.createdBy.username || this.cookieCommentId >= comment.id);

            if (comment.isNew) {
              this.isNewComment = true;
            }
          });
        } else {
          this.comments = new Comments();
          this.comments.comments = [];

          this.isNewComment = false;
        }

        this.isShowLnbLoading = false;
      })
      .catch((err) => {
        this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
        console.log(err);
        this.isShowLnbLoading = false;
      });
  }

  private _dashboardValidation(dashboard: Dashboard) {
    if (StringUtil.isEmpty(dashboard.name)) {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.name.empty'));
      return false;
    }

    if (CommonUtil.getByte(dashboard.name) > 150) {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.name.len'));
      return false;
    }

    if (dashboard.description != null && CommonUtil.getByte(dashboard.description) > 450) {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.description.len'));
      return false;
    }

    return true;
  }

  private _initViewPage() {
    this.page.size = 100000;

    this.workbook = createWorkbookDetailProjections() as WorkbookDetailProjections;
    this.workbook.modifiedBy = {} as UserProfile;
    this.workbook.createdBy = {} as UserProfile;
    this.leftTab = 'DASHBOARD';
    this.listType = 'CARD';
    this.dashboards = [];

    this.onlyShowingFlag = false;

    this.comments = new Comments();
    this.comments.comments = [];

    this.username = CommonUtil.getLoginUserId();

    const cookieIsCloseDashboardList = this.cookieService.get(CookieConstant.KEY.WORKBOOK_CLOSE_DASHBOARD_LIST);
    this.toggleFoldDashboardList('true' === cookieIsCloseDashboardList);

    this.changeMode('NO_DATA');
  }

  private _setSelectedDashboard(dashboard) {
    this.selectedDashboard = dashboard;
    this.dashboardService.setCurrentDashboard(dashboard);
    this.goToDashboard(dashboard);
  }

  public getDashboard(event) {
    const dashboard = this.dashboards[0];
    if (event) {
      const dashboard = event;
      this.detailDashboard(dashboard);
    }
  }
  public getSearch(event) {
    this.searchDashboardByNames(event);
  }

  gettingConfirmDel: any = false;
  public getConfirmDelete(event) {
    this.gettingConfirmDel = event;
  }

  public gettingModalProfile: any = false;
  public getModalProfile(event) {
    this._ptService.ptModalClose = event;
  }

  public clientIDData;
  public getClientIDData(event) {
    this.clientIDData = event;
    console.group('Client ID');

    console.groupEnd();
  }

  setConfirmDelete(event) {
    if (event != false) {
      if (typeof this.gettingConfirmDel == 'string') {
        this._workbookNavigationComponent.deleteDashboardId(this.gettingConfirmDel);
        this.gettingConfirmDel = false;
      } else {
        this._workbookNavigationComponent.deleteDashboard(this.gettingConfirmDel);
        this.gettingConfirmDel = false;
      }
    } else {
      this.gettingConfirmDel = false;
    }
  }
  public searchDashboardByNames(event: any) {
    this.dashboardSrchText = event.target['value'].trim();
    event.stopPropagation();
    this.loadDashboardList(0);
  }

  public setCloseWindow(event) {
    this.gettingModalProfile = event;
  }

  public dashboardClone(dashboard: Dashboard) {
    this.copyDashboard(dashboard);
  }

  goToDashboard(dashboard: Dashboard) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { dashboardId: dashboard.id },
      queryParamsHandling: 'merge',
    });
  }
}
