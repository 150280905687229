import { Injectable, Injector } from '@angular/core';

import * as _ from 'lodash';
import { from } from 'rxjs';

import { ChartUtil } from '@selfai-platform/bi-chart-engine';
import {
  ChartType,
  CustomField,
  Dashboard,
  Datasource,
  DatasourceField as Field,
  Filter,
  FilteringType,
  FormatType,
  GeoBoundaryFormat,
  GeoHashFormat,
  GridViewType,
  InclusionFilter,
  LayerViewType,
  LineMode,
  LogicalType,
  MapLayerType,
  MeasureInequalityFilter,
  MeasurePositionFilter,
  PageWidgetConfiguration,
  Pivot,
  RegExprFilter,
  SearchQueryRequest,
  Shelf,
  ShelfType,
  ShelveFieldType,
  TimeCompareRequest,
  TimeFilter,
  UIGridChart,
  UILineChart,
  UIMapOption,
  UITileLayer,
  WildCardFilter,
  createSearchQueryRequest,
  createSpatialFilter,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { CommonConstant } from '../../common/constant/common.constant';
import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';
import { DashboardUtil } from '../../dashboard/util/dashboard.util';
import { FilterUtil } from '../../dashboard/util/filter.util';
import { TimezoneService } from '../../data-storage/service/timezone.service';
import { Criteria } from '../../domain/datasource/criteria';

@Injectable()
export class DatasourceService extends AbstractService {
  private _useMetaDataQuery = false;

  private _timezoneSvc: TimezoneService;

  private _searchHistory: { query: string; result: any }[] = [];

  constructor(protected injector: Injector) {
    super(injector);
    this._timezoneSvc = this.injector.get(TimezoneService);
  }

  public getDatasources(workspaceId: string, params?: any, projection: string = 'default'): Promise<any> {
    let url = this.API_URL + `workspaces/${workspaceId}/datasources?projection=${projection}`;

    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }

    return this.get(url);
  }

  public getDatasourceSummary(datasorceId: string): Promise<Datasource> {
    const url = this.API_URL + `datasources/${datasorceId}?projection=forDetailView`;

    return this.get(url);
  }

  public getDatasourceQuery(options: any) {
    const url = this.API_URL + 'datasources/query/search';

    return this.post(url, options);
  }

  public getDatasourceSimilarity(dataSourceName: string, compareDataSourceName: string): Promise<any> {
    return new Promise<any>((reslove, reject) => {
      reslove([]);
    });

    //TODO need fix on backend
    // const url = this.API_URL + `datasources/query/similarity`;
    // const params = {
    //   dataSources: [dataSourceName, compareDataSourceName],
    // };

    // return this.post(url, params);
  }

  public searchQuery(query: SearchQueryRequest, disableCache?: boolean): Promise<any> {
    const stringifyQuery = JSON.stringify(query);
    const historyItem = this._searchHistory.find((history) => history.query === stringifyQuery);
    if (query.filters && query.filters.some((t) => t.type === 'empty')) {
      return new Promise((resolve, reject) => {
        resolve({
          rows: [],
          columns: [],
          info: {
            maxValue: Infinity,
            minValue: -Infinity,
            totalCategory: 0,
          },
        });
      });
    }

    if (!disableCache && historyItem) {
      return new Promise((resolve, reject) => {
        resolve(historyItem.result);
      });
    } else {
      return this.post(this.API_URL + 'datasources/query/search', query).then((result) => {
        this._searchHistory.push({ query: stringifyQuery, result: result });
        return result;
      });
    }
  }

  public timeCompareQuery(query: TimeCompareRequest): Promise<any> {
    return this.post(this.API_URL + 'datasources/query/search/time_compare', query);
  }

  public getCandidate(params): Promise<any> {
    return this.post(this.API_URL + 'datasources/query/candidate', params);
  }

  public getCandidateForFilter(
    filter: Filter,
    board: Dashboard,
    filters?: Filter[],
    field?: Field | CustomField,
    sortBy?: string,
    searchWord?: string,
  ): Promise<any> {
    const param: any = {};
    param.dataSource = DashboardUtil.getDataSourceForApi(
      _.cloneDeep(FilterUtil.getBoardDataSourceForFilter(filter, board)),
    );
    param.filters =
      filters && 0 < filters.length ? _.cloneDeep(filters) : FilterUtil.getEssentialFilters(board, filter);
    param.filters = param.filters
      .map((item) => FilterUtil.convertToServerSpec(item))
      .filter((item) => !(item.type === 'bound' && item['min'] == null));

    if (FilterUtil.isTimeFilter(filter)) {
      const timeFilter: TimeFilter = <TimeFilter>filter;
      if (CommonConstant.COL_NAME_CURRENT_DATETIME === timeFilter.field) {
        param.targetField = { granularity: 'ALL', name: CommonConstant.COL_NAME_CURRENT_DATETIME, type: 'timestamp' };
      } else {
        param.targetField = {
          type: 'timestamp',
          name: timeFilter.field,
          alias: timeFilter.field,
          format: {
            type: 'time_continuous',
            discontinuous: FilterUtil.isDiscontinuousTimeFilter(timeFilter),
            unit: timeFilter.timeUnit,
            filteringType: FilterUtil.isTimeListFilter(timeFilter) ? FilteringType.LIST : FilteringType.RANGE,
          },
        };
        timeFilter.byTimeUnit && (param.targetField.format.byUnit = timeFilter.byTimeUnit);
        param.sortBy = sortBy ? sortBy : 'COUNT';
      }

      param.targetField.type = 'timestamp';
    } else {
      if (isNullOrUndefined(field)) {
        board.dataSources.some((ds) => {
          if (ds.engineName === filter.dataSource) {
            return ds.fields.some((dsField) => {
              if (dsField.name === filter.field) {
                field = dsField;
                return true;
              } else {
                return false;
              }
            });
          }
        });
      }

      param.targetField = { alias: field.alias, name: field.name };
      if ('user_expr' === field.type) {
        param.targetField.ref = 'user_defined';
      } else if (field.ref) {
        param.targetField.ref = field.ref;
      }

      board.configuration.customFields && (param.userFields = board.configuration.customFields);

      if ('include' === filter.type) {
        const tempFilters: Filter[] = [];
        if ((<InclusionFilter>filter).preFilters) {
          (<InclusionFilter>filter).preFilters.filter((preFilter: Filter) => {
            if (preFilter.type === 'measure_inequality') {
              const condition: MeasureInequalityFilter = <MeasureInequalityFilter>preFilter;
              if (condition.inequality && condition.aggregation && condition.field && 0 < condition.value) {
                tempFilters.push(FilterUtil.convertToServerSpec(condition));
              }
            } else if (preFilter.type === 'measure_position') {
              const limitation: MeasurePositionFilter = <MeasurePositionFilter>preFilter;
              if (limitation.position && limitation.aggregation && limitation.field && 0 < limitation.value) {
                tempFilters.push(FilterUtil.convertToServerSpec(limitation));
              }
            } else if (preFilter.type === 'wildcard') {
              const wildcard: WildCardFilter = <WildCardFilter>preFilter;
              if (wildcard.contains && wildcard.value && wildcard.value.length > 0) {
                tempFilters.push(FilterUtil.convertToServerSpec(wildcard));
              }
            } else if (preFilter.type === 'regexpr') {
              const regExpr: RegExprFilter = <RegExprFilter>preFilter;
              if (regExpr.expr) {
                tempFilters.push(FilterUtil.convertToServerSpec(regExpr));
              }
            }
          });
        }
        param.filters = param.filters.concat(tempFilters);
        param.targetField && (param.targetField.type = 'dimension');

        param.sortBy = sortBy ? sortBy : 'COUNT';
        param.searchWord = searchWord ? searchWord : '';
        param.limit = FilterUtil.CANDIDATE_LIMIT;
      } else if ('bound' === filter.type) {
        if (param.targetField) {
          param.targetField.aggregationType = 'NONE';
          param.targetField.type = 'measure';
        }
      }
    }

    param.filters = param.filters.filter((item) => !(item.type === 'bound' && item['min'] == null));

    param.valueAliasRef = board.id;

    return this.post(this.API_URL + 'datasources/query/candidate', param);
  }

  public makeQuery(
    pageConf: PageWidgetConfiguration,
    dataSourceFields: Field[],
    context: { url: string; dashboardId: string; widgetId?: string },
    resultFormatOptions?: any,
    isChartData?: boolean,
  ): SearchQueryRequest {
    const query: SearchQueryRequest = createSearchQueryRequest();

    query.context = {
      'discovery.route.uri': context.url,
      'discovery.dashboard.id': context.dashboardId,
    };
    context.widgetId && (query.context['discovery.widget.id'] = context.widgetId);

    if (pageConf.customFields) {
      query.userFields = CommonUtil.objectToArray(pageConf.customFields).filter((item) => {
        return item.dataSource === pageConf.dataSource.engineName || item.dataSource === pageConf.dataSource.name;
      });
    }

    query.filters = _.cloneDeep(pageConf.filters);
    query.pivot = _.cloneDeep(pageConf.pivot);

    let allPivotFields = [];

    query.dataSource = _.cloneDeep(pageConf.dataSource);
    delete query.dataSource['fields'];

    if (!isNullOrUndefined(query.dataSource.engineName)) {
      query.dataSource.name = query.dataSource.engineName;
    }

    if (_.eq(pageConf.chart.type, ChartType.MAP)) {
      query.analysis = _.cloneDeep((<UIMapOption>pageConf.chart).analysis);

      query.shelf = _.cloneDeep(pageConf.shelf);

      let layerNum = -1;
      for (const layer of query.shelf.layers) {
        layerNum++;

        if (layerNum > 1) {
          break;
        }

        query.shelf.layers[layerNum].name = (<UIMapOption>pageConf.chart).layers[layerNum].name;

        allPivotFields = _.concat(layer.fields);
        for (const field of allPivotFields) {
          field['alias'] = this._setFieldAlias(field, dataSourceFields);

          if (
            field['type'].toUpperCase() == 'TIMESTAMP' &&
            field['subRole'] &&
            field['subRole'].toUpperCase() == 'DIMENSION'
          ) {
            field['type'] = 'dimension';
          }

          if (_.eq(pageConf.chart.type, 'grid') && (<UIGridChart>pageConf.chart).dataType == GridViewType.MASTER) {
            field['aggregationType'] = 'NONE';
          }
        }

        if (allPivotFields.length == 0) {
          continue;
        }

        {
          const shelfConf: Shelf = query.shelf;
          if (shelfConf.layers && 0 < shelfConf.layers.length) {
            shelfConf.layers[layerNum].fields.forEach((field) => {
              if (
                (LogicalType.TIMESTAMP.toString() === field.type.toUpperCase() ||
                  LogicalType.TIMESTAMP.toString() === field.subType ||
                  LogicalType.TIMESTAMP.toString() === field.subRole) &&
                field.format
              ) {
                const dsField: Field = dataSourceFields.find((item) => item.name === field.name);
                if (dsField && dsField.format && TimezoneService.DISABLE_TIMEZONE_KEY === dsField.format['timeZone']) {
                  delete field.format['timeZone'];
                  delete field.format['locale'];
                } else {
                  field.format['timeZone'] = this._timezoneSvc.getBrowserTimezone().momentName;
                  field.format['locale'] = this._timezoneSvc.browserLocale;
                }
              }
            });
          }
        }
      }
    } else {
      {
        const pivotConf: Pivot = query.pivot;
        if (pivotConf.columns && 0 < pivotConf.columns.length) {
          pivotConf.columns.forEach((column) => {
            if (
              (LogicalType.TIMESTAMP.toString() === column.type.toUpperCase() ||
                LogicalType.TIMESTAMP.toString() === column.subType ||
                LogicalType.TIMESTAMP.toString() === column.subRole) &&
              column.format
            ) {
              const dsField: Field = dataSourceFields?.find((item) => item.name === column.name);
              if (dsField && dsField.format && TimezoneService.DISABLE_TIMEZONE_KEY === dsField.format['timeZone']) {
                delete column.format['timeZone'];
                delete column.format['locale'];
              } else {
                column.format.timeZone = this._timezoneSvc.getBrowserTimezone().momentName;
                column.format.locale = this._timezoneSvc.browserLocale;
              }
            }
          });
        }
        if (pivotConf.rows && 0 < pivotConf.rows.length) {
          pivotConf.rows.forEach((row) => {
            if (
              (LogicalType.TIMESTAMP.toString() === row.type.toUpperCase() ||
                LogicalType.TIMESTAMP.toString() === row.subType ||
                LogicalType.TIMESTAMP.toString() === row.subRole) &&
              row.format
            ) {
              const dsField: Field = dataSourceFields.find((item) => item.name === row.name);
              if (dsField && dsField.format && TimezoneService.DISABLE_TIMEZONE_KEY === dsField.format['timeZone']) {
                delete row.format['timeZone'];
                delete row.format['locale'];
              } else {
                row.format.timeZone = this._timezoneSvc.getBrowserTimezone().momentName;
                row.format.locale = this._timezoneSvc.browserLocale;
              }
            }
          });
        }
      }

      allPivotFields = _.concat(query.pivot.columns, query.pivot.rows, query.pivot.aggregations);
      for (const field of allPivotFields) {
        field['alias'] = this._setFieldAlias(field, dataSourceFields);

        if (
          field['type'].toUpperCase() == 'TIMESTAMP' &&
          field['subRole'] &&
          field['subRole'].toUpperCase() == 'DIMENSION'
        ) {
          field['type'] = 'dimension';
        }

        if (_.eq(pageConf.chart.type, 'grid') && (<UIGridChart>pageConf.chart).dataType == GridViewType.MASTER) {
          field['aggregationType'] = 'NONE';
        }
      }
    }
    const limit = pageConf.chart.limit || 100000;

    query.limits = { ...pageConf.limit, limit };

    {
      query.aliases = [];
      query.aliases.push({
        type: 'value',
        ref: context.dashboardId,
      });
      if (this._useMetaDataQuery) {
        const codeTablesFields = dataSourceFields.filter((field) => {
          return field.type !== 'user_expr' && field.uiMetaData && field.uiMetaData.codeTable;
        });
        if (0 < codeTablesFields.length) {
          query.aliases.push({
            type: 'code',
            codes: codeTablesFields.reduce((acc, currVal) => {
              acc[currVal.name] = currVal.uiMetaData.codeTable.id;
              return acc;
            }, {}),
          });
        }
      }
    }

    if (!_.eq(pageConf.chart.type, 'graph') && !_.eq(pageConf.chart.type, 'sankey')) {
      query.resultFormat = {
        type: 'chart',
        mode: pageConf.chart.type,
        options: {
          addMinMax: true,
        },
        columnDelimeter: '―',
      };

      if (!_.eq(pageConf.chart.type, 'grid')) {
        query.resultFormat.options['showCategory'] = true;
        query.resultFormat.options['showPercentage'] = true;
      }

      if (ChartType.LINE === pageConf.chart.type || ChartType.CONTROL === pageConf.chart.type) {
        query.resultFormat.options.isCumulative = _.eq(LineMode.CUMULATIVE, (<UILineChart>pageConf.chart).lineMode);
      }

      if (ChartType.GRID === pageConf.chart.type) {
        if (_.eq((<UIGridChart>pageConf.chart).dataType, GridViewType.MASTER)) {
          query.resultFormat.options.isOriginal = true;
        } else {
          delete query.resultFormat.options.isOriginal;
        }
      }
    } else if (_.eq(pageConf.chart.type, 'sankey')) {
      query.resultFormat = {
        type: 'graph',
      };
    } else if (_.eq(pageConf.chart.type, 'graph')) {
      query.resultFormat = {
        type: 'graph',
        useLinkCount: true,
        mergeNode: true,
      };
    } else if (_.eq(pageConf.chart.type, 'graphv2')) {
      query.resultFormat = {
        type: 'graph',
        useLinkCount: true,
        mergeNode: true,
      };
    }

    if (_.eq(pageConf.chart.type, ChartType.MAP)) {
      const geoFieldArr: number[] = [];

      for (let idx = 0; idx < query.shelf.layers.length; idx++) {
        let geoFieldCnt = 0;
        for (const column of query.shelf.layers[idx].fields) {
          if (
            column &&
            column.field &&
            column.field.logicalType &&
            -1 !== column.field.logicalType.toString().indexOf('GEO')
          ) {
            geoFieldCnt++;
          }
        }
        geoFieldArr.push(geoFieldCnt);
      }

      for (let idx = 0; idx < query.shelf.layers.length; idx++) {
        for (const layer of query.shelf.layers[idx].fields) {
          if ('measure' === layer.type) {
            layer.aggregationType = layer.aggregationType;
          } else if ('dimension' === layer.type) {
            const radius = (<UITileLayer>(<UIMapOption>pageConf.chart).layers[idx]).radius;

            let precision = Math.round((100 - radius) / 8.33);

            if (precision > 12) precision = 12;
            if (precision < 1) precision = 1;

            if (layer.field && layer.field.logicalType) {
              if (layer.field.logicalType && layer.field.logicalType.toString().indexOf('GEO') > -1) {
                layer.format = {
                  type: FormatType.GEO.toString(),
                };

                const chart = <UIMapOption>pageConf.chart;
                if (chart.layers[idx].type == MapLayerType.CLUSTER && chart.layers[idx]['clustering']) {
                  let clusterPrecision = 6;
                  if (chart['layers'][idx]['changeCoverage']) {
                    clusterPrecision = Math.round((100 - chart.layers[idx]['coverage']) / 8.33);
                  } else {
                    const zoomSize = chart.zoomSize - 2;
                    clusterPrecision = Math.round((18 + (zoomSize - 18)) / 1.5);
                  }

                  if (clusterPrecision > 12) clusterPrecision = 12;
                  if (clusterPrecision < 1) clusterPrecision = 1;

                  query.shelf.layers[idx].view = <GeoHashFormat>{
                    type: LayerViewType.CLUSTERING.toString(),
                    method: 'h3',

                    precision: _.isNaN(clusterPrecision) ? 6 : clusterPrecision,
                  };
                } else if (chart.layers[idx].type == MapLayerType.SYMBOL) {
                  const precision = 12;
                  query.shelf.layers[idx].view = <GeoHashFormat>{
                    type: 'abbr',
                    method: 'h3',
                    relayType: 'FIRST',

                    precision: precision,
                  };
                }

                if (
                  !_.isUndefined(chart['lowerCorner']) &&
                  !_.isUndefined(chart['upperCorner']) &&
                  chart['lowerCorner'].indexOf('NaN') == -1 &&
                  chart['upperCorner'].indexOf('NaN') == -1
                ) {
                  const spatialFilter = createSpatialFilter();
                  spatialFilter.dataSource = query.shelf.layers[idx].ref;

                  spatialFilter.field = layer.field.name;

                  spatialFilter.lowerCorner = chart['lowerCorner'];
                  spatialFilter.upperCorner = chart['upperCorner'];
                  query.filters.push(spatialFilter);
                }
              }

              if (layer.field.logicalType === LogicalType.GEO_POINT) {
                if (MapLayerType.TILE === (<UIMapOption>pageConf.chart).layers[idx].type) {
                  const chart = <UIMapOption>pageConf.chart;
                  let radiusPrecision: number = precision;
                  if (chart['layers'][idx]['changeTileRadius']) {
                    radiusPrecision = precision;
                  } else {
                    const zoomSize = chart.zoomSize - 2;
                    radiusPrecision = Math.round((18 + (zoomSize - 18)) / 1.5);

                    chart['layers'][idx]['changeTileRadius'] = true;
                    chart['layers'][idx]['radius'] = Math.round(100 - radiusPrecision * 8.33);
                    chart['layers'][idx]['tileRadius'] = chart['layers'][idx]['radius'];
                  }

                  if (radiusPrecision > 12) radiusPrecision = 12;
                  if (radiusPrecision < 1) radiusPrecision = 1;

                  query.shelf.layers[idx].view = <GeoHashFormat>{
                    type: LayerViewType.HASH.toString(),
                    method: 'geohex',
                    precision: radiusPrecision,
                  };
                }

                if (geoFieldArr[idx] > 1) {
                  layer.format = <GeoBoundaryFormat>{
                    type: FormatType.GEO_BOUNDARY.toString(),
                    geoColumn: query.pivot.columns[0].field.name,
                    descColumn: query.pivot.columns[0].field.name,
                  };
                }
              } else if (
                layer.field.logicalType === LogicalType.GEO_POLYGON ||
                layer.field.logicalType === LogicalType.GEO_LINE
              ) {
                if (geoFieldArr[idx] > 1) {
                  layer.format = {
                    type: FormatType.GEO_JOIN.toString(),
                  };
                }
              }
            }
          }
        }
      }

      query.shelf = {
        type: ShelfType.GEO,
        layers: query.shelf.layers,
      };

      query.limits = {
        limit: 5000,
        sort: null,
      };
      const uiOption = <UIMapOption>pageConf.chart;
      uiOption.layers.forEach((layer) => {
        if (layer.type == MapLayerType.SYMBOL || layer.type == MapLayerType.HEATMAP) {
          query.limits.limit = 20000;
        }
      });
    }

    if (!_.isEmpty(resultFormatOptions)) {
      query.resultFormat.options = _.extend(query.resultFormat.options, resultFormatOptions);
    }

    if (!isChartData && query.dataSource.type === 'mapping') {
      query.dataSource['joins'].forEach((join) => {
        join.name = join.engineName;
      });
    }

    return query;
  }

  public getAllDatasource(param: any, projection: string = 'forListView'): Promise<any> {
    let url = this.API_URL + 'datasources';

    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }

    return this.get(url + `&projection=${projection}`);
  }

  public deleteDatasource(datasourceId: string): Promise<any> {
    return this.delete(this.API_URL + `datasources/${datasourceId}`);
  }

  public createDatasource(param: any): Promise<any> {
    return this.post(this.API_URL + 'datasources', param);
  }

  public createDatasourceTemporary(param: any): Promise<any> {
    return this.post(this.API_URL + 'datasources/temporary', param);
  }

  public appendDatasource(datasourceId: string, param: any): Promise<any> {
    return this.patch(this.API_URL + `datasources/${datasourceId}/append`, param);
  }

  public overwriteDatasource(datasourceId: string, param: any): Promise<any> {
    return this.patch(this.API_URL + `datasources/${datasourceId}/overwrite`, param);
  }

  public createLinkedDatasourceTemporary(dataSourceId: string, param?: Filter[], async: boolean = true) {
    return this.post(this.API_URL + `datasources/${dataSourceId}/temporary?async=${async}`, param);
  }

  public updateDatasource(datasourceId: string, param: any): Promise<any> {
    return this.patch(this.API_URL + 'datasources/' + datasourceId, param);
  }

  public saveConnection(param: any): Promise<any> {
    return this.post(this.API_URL + 'connections', param);
  }

  public getDatasourceDetail(datasourceId: string, includeUnloadedField?: boolean): Promise<Datasource> {
    const url =
      this.API_URL +
      (includeUnloadedField
        ? `datasources/${datasourceId}?projection=forDetailView&includeUnloadedField=${includeUnloadedField}`
        : `datasources/${datasourceId}?projection=forDetailView`);
    return this.get(url);
  }

  public checkValidationDateTime(param: any): Promise<any> {
    return this.post(this.API_URL + 'datasources/validation/datetime', param);
  }

  public checkValidationCron(param: any): Promise<any> {
    let url = this.API_URL + 'datasources/validation/cron';
    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }
    return this.post(url, null);
  }

  public addDatasourceWorkspaces(datasourceId: string, param: any): Promise<any> {
    const connIds = param
      .map((id) => {
        return '/api/workspaces/' + id;
      })
      .join('\n');
    return this.patch(this.API_URL + `datasources/${datasourceId}/workspaces`, connIds, 'text/uri-list');
  }

  public deleteDatasourceWorkspaces(datasourceId: string, param: any): Promise<any> {
    const connIds = param
      .map((connection) => {
        return connection;
      })
      .join(',');
    return this.delete(this.API_URL + `datasources/${datasourceId}/workspaces/${connIds}`);
  }

  public getFieldStats(params: any): Promise<any> {
    return this.post(this.API_URL + 'datasources/stats', params);
  }

  public getBatchHistories(datasourceId: string, params: any): Promise<any> {
    let url = this.API_URL + `datasources/${datasourceId}/ingestion/histories`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getQueryHistories(datasourceId: string, params: any): Promise<any> {
    let url = this.API_URL + `datasources/${datasourceId}/query/histories`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getQueryHistoriesCount(datasourceId: string): Promise<any> {
    const url = this.API_URL + `datasources/${datasourceId}/query/histories/stats/count`;
    return this.get(url);
  }

  public getHistoriesSize(datasourceId: string): Promise<any> {
    const url = this.API_URL + `datasources/${datasourceId}/histories/size/stats/hour`;
    return this.get(url);
  }

  public getQueryHistoriesStatsUser(datasourceId: string, duration: string = '-P7D'): Promise<any> {
    const url = this.API_URL + `datasources/${datasourceId}/query/histories/stats/user?duration=${duration}`;
    return this.get(url);
  }

  public getQueryHistoriesStatsTime(datasourceId: string, duration: string = '-P7D'): Promise<any> {
    const url = this.API_URL + `datasources/${datasourceId}/query/histories/stats/elapsed?duration=${duration}`;
    return this.get(url);
  }

  public getDatasourceFile(fileKey: string, params: any): Promise<any> {
    let url = this.API_URL + 'datasources/file/' + fileKey + '/data';
    if (params) {
      const replaceParams = {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          replaceParams[key] = (params[key] + '').replace(/\\n/gi, '\n').replace(/\\r/gi, '\r').replace(/\\t/gi, '\t');
        }
      }
      url += '?' + CommonUtil.objectToUrlString(replaceParams);
    }

    return this.getFormData(url);
  }

  public getDatasourceImportableEngineList(): Promise<any> {
    return this.get(this.API_URL + 'datasources/import/datasources');
  }

  public getDatasourceImportableEnginePreview(engineName: string, params: object): Promise<any> {
    let url = this.API_URL + `datasources/import/${engineName}/preview`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public importDatasource(engineName: string): Promise<any> {
    return this.post(this.API_URL + `datasources/import/${engineName}`, null);
  }

  public updateDatasourceFields(datasourceId: string, params: any): Promise<any> {
    return this.patch(this.API_URL + `datasources/${datasourceId}/fields`, params);
  }

  public getDefaultIngestionOptions(ingestionType: string): Promise<any> {
    return this.get(this.API_URL + `datasources/ingestion/options?ingestionType=${ingestionType}`);
  }

  public synchronizeDatasourceFields(datasourceId: string): Promise<any> {
    return this.patch(this.API_URL + `datasources/${datasourceId}/fields/sync`, null);
  }

  public getDatasourceIngestionLog(datasourceId: string, historyId: string, offset?: number): Promise<any> {
    if (offset) {
      return this.get(this.API_URL + `datasources/${datasourceId}/histories/${historyId}/log?offset=${offset}`);
    } else {
      return this.get(this.API_URL + `datasources/${datasourceId}/histories/${historyId}/log`);
    }
  }

  public getCriterionListInDatasource() {
    return this.get(this.API_URL + 'datasources/criteria');
  }

  public getCriterionInDatasource(criterionKey: Criteria.ListCriterionKey) {
    return this.get(this.API_URL + `datasources/criteria/${criterionKey}`);
  }

  public getDatasourceListForCheckName(containsText: string) {
    return from(this.getDatasourceList(0, 999, '', { containsText }));
  }

  public getDatasourceList(
    page: number,
    size: number,
    sort: string,
    params: any,
    projection: string = 'forListView',
  ): Promise<any> {
    return this.post(
      this.API_URL + `datasources/filter?projection=${projection}&page=${page}&size=${size}&sort=${sort}`,
      params,
    );
  }

  public getKafkaTopic(bootstrapServer: string) {
    return this.postWithForm(this.API_URL + `datasources/kafka/topic`, `bootstrapServer=${bootstrapServer}`);
  }

  public getKafkaTopicData(bootstrapServer: string, topic: string) {
    return this.postWithForm(
      this.API_URL + `datasources/kafka/data`,
      `bootstrapServer=${bootstrapServer}&topic=${topic}`,
    );
  }

  private _setFieldAlias(field: any, dataSourceFields: Field[]): any {
    if (this._useMetaDataQuery) {
      const dsField: Field = dataSourceFields.find(
        (item) => item.name === field.name && !isNullOrUndefined(item.uiMetaData),
      );
      dsField && (field['logicalName'] = dsField.uiMetaData.name);
    }

    if (ShelveFieldType.MEASURE.toString() === field.type) {
      field['alias'] = ChartUtil.getAlias(field);
    } else if (ShelveFieldType.TIMESTAMP.toString() === field.type) {
      const alias: string = field['alias']
        ? field['alias']
        : field['fieldAlias']
        ? field['fieldAlias']
        : field['logicalName']
        ? field['logicalName']
        : field['name'];
      if (alias === field.name) {
        if (field.format && field.format.unit)
          field['alias'] = (field.format && field.format.unit ? field.format.unit : 'NONE') + `(${alias})`;
      }
    } else {
      field['alias'] = ChartUtil.getAlias(field);
    }

    return field['alias'];
  }
}
