import { BookModel, WorkspaceDetailViewModel } from '@selfai-platform/bi-api';
import { BiEntityPermission } from '@selfai-platform/shared';
import { HistoryEntity } from '../../core';
import { Dashboard } from '../../dashboard';
import { Datasource, ImplementorType } from '../../datasource';
import { UserProfile } from '../../user';

export interface Book extends HistoryEntity {
  id: string;
  type: string;
  name: string;
  description?: string;
  favorite?: boolean;
  tag?: string;
  workspace?: any;
  folderId?: string;
  contents?: Contents;
  kernelType?: string;
  dsName?: string;
  checked?: boolean;
  edit?: boolean;
}

export function createBook(params: Partial<Book> = {}): Partial<Book> {
  return {
    ...params,
  };
}

export interface Workbook extends Book {
  workspaceId: string;
  configuration: any;
  pages: any[];
  dataSources: any[];
  dataSource: Datasource[];
  workBookType?: string;
  permissions?: BiEntityPermission[];
}

export function createWorkbook(params: Partial<Workbook> = {}): Partial<Workbook> {
  return {
    ...createBook(),
    configuration: {},
    pages: [],
    dataSources: [],
    ...params,
  };
}

export interface WorkbookDefaultProjections extends Workbook {
  createdBy: UserProfile;
  modifiedBy: UserProfile;
  folderId: string;
  workspaceId: string;
}

export interface WorkbookDetailProjections extends Workbook {
  dashBoards?: Dashboard[];
  countOfComments?: number;
  dataSource: Datasource[];
  workspaceId: string;
}

export type WorkbookListItem = Pick<
  BookModel,
  'id' | 'name' | 'author' | 'createdTime' | 'modifiedTime' | 'permissions'
>;

export function createWorkbookDetailProjections(
  params: Partial<WorkbookDetailProjections> = {},
): Partial<WorkbookDetailProjections> {
  return {
    ...createWorkbook(),
    ...params,
  };
}

export interface Contents {
  dataSource: number;
  dashboard: number;
  connType: ImplementorType;
  connName: string;
  connValid: boolean;
}

export interface BookTree {
  name: string;
  id: string;
  type: string;

  books: BookTree[];
  dashBoards: BookTree[];
  hierarchies: any[];
}

export type WorkspaceInWorkbookList = Omit<WorkspaceDetailViewModel, 'books' | 'booksContour'>;

export interface WorkbookList {
  workspace: WorkspaceInWorkbookList;
  workbooks: WorkbookListItem[];
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface WorkbookAdd {
  name: string;
  description?: string;
  folderId?: string;
}
